.tooltip-container {
    position: relative;
    display: inline-block;

    .tooltip-icon {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    $background-color: #2a3b43;

    .tooltip {
        background-color: $background-color;
        color: white;
        border-radius: 6px;
        padding: 12px;
        position: absolute;
        z-index: 10;

        top: -10px;
        left: 50%;
        transform: translate(-50%, -100%);

        width: 200px;

        animation: fadeIn 0.3s ease-in-out forwards;

        &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: $background-color;
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        @media only screen and (min-width: 768px) {
            left: initial;

            top: 50%;
            right: -10px;
            transform: translate(100%, -50%);
            width: 250px;

            &:after {
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}