.phone-input {
    --react-international-phone-border-radius: 12px;
  
    .react-international-phone-country-selector button {
      height: 100%;
    }
  
    input {
      width: 100% !important;
      box-sizing: border-box !important;
      min-height: 50px !important;
      border-radius: 0 12px 12px 0 !important;
      border: 1px solid #d9d9d9 !important;
      padding: 0 15px !important;
      outline: 0 !important;
      color: #455560 !important;
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
  