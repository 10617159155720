.customer_content button.primary{
    border-radius: 30px;
    background-color: #333E48;
    border: 1px solid #333E48;
    text-decoration: none;
    font-size: 14px;
    color: var(--white);
    padding: 6px 20px;
    text-align: center;
    transition: all ease-in-out 200ms;
    line-height: 31px;
    width: 180px;

    &.full-width {
        max-width: 100%;
        width: 100%;
    }

    &:hover {
        background: #ffffff;
        border-color: #455560;
        color: #455560;
        transition: 0.5s;
    }

    &:active {
        background-color: #2a3b43;
        border-color: #2a3b43;
    }

    &:disabled {
        background-color: #e9e9e9;
        border: 1px solid #e9e9e9;
        color: #c8cbcd;
        cursor: default;
        pointer-events: none;
    }
}

button.primary {
    border-radius: 50px;
    background-color: #455560;
    border: 1px solid #455560;
    text-decoration: none;
    font-size: 14px;
    color: var(--white);
    padding: 12px 24px;
    text-align: center;
    transition: all ease-in-out 200ms;
  
    &.full-width {
      max-width: 100%;
      width: 100%;
    }
  
    &:hover {
      background-color: #34454f;
      border-color: #34454f;
    }
  
    &:active {
      background-color: #2a3b43;
      border-color: #2a3b43;
    }
  
    &:disabled {
      background-color: #e9e9e9;
      border: 1px solid #e9e9e9;
      color: #c8cbcd;
      cursor: default;
      pointer-events: none;
    }
  }
  