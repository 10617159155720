.order-completed-page {
    display: flex;
    flex-direction: column;
    padding: 100px 20px;
    align-items: center;
  
    .title {
      text-align: center;
      font-size: 34px;
      line-height: 44px;
      color: #455560;
    }
  
    .success-text {
      margin-top: 34px;
      max-width: 478px;
      text-align: center;
      font-size: 14px;
      line-height: 34px;
      color: #455560;
    }
  
    .actions {
      margin-top: 40px;
    }
  }
  