#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root .main_content {
    flex: 1;
}

.main_title {
    padding: 1px 0px;
}

.header_links .country_dropdown select {
    background: transparent;
    border: none;
    width: 100%;
    padding: 0 35px;
    outline: 0;
    min-height: 60px;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 35px center;
    background-size: 1.5em;
}

.header_links .country_dropdown select option {
    background: #fff;
    color: #000;
}

.footer_dropdown .styled-select {
    box-sizing: border-box;
    border-radius: 23px;
    border: 1px solid #fff;

    overflow: hidden;
    position: relative;
}

.footer_dropdown .styled-select,
.footer_dropdown .styled-select select {
    max-width: 120px;
}

.footer_dropdown select:focus {
    outline: none;
}

.footer_dropdown .styled-select select {
    padding: 15px 10px 15px 30px;
    background: transparent;
    border: none;

    /*hide default down arrow in webkit */
    -webkit-appearance: none;
}

.footer_dropdown {
    position: relative;
    width: 100%;
    max-width: 86px;
    margin-left: auto;
    margin-bottom: 26px;
}

.footer_dropdown select,
i {
    color: white;
}

.footer_dropdown select option {
    color: black;
}

.footer_dropdown .country_options {
    display: none;
    position: absolute;
    top: 43px;
    right: 0;
    width: 100%;
    background: transparent;
    border-radius: 5px;
    border: 1px solid #fff;
}

.tal {
    text-align: left;
}

.fl {
    float: left;
}

.Scopri_button_section {
    width: 200px;
    background-color: #455560;
    border-radius: 12px;
    text-align: center;
}

.Scopri_button_section button {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'DIN2014';
}

.p_details_ {
    font-size: 14px;
    line-height: 18px;
    color: #455560;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.username_header {
    max-width: 200px;
    white-space: nowrap;
}

.contact.footer_links .social ul a:after {
    color: #455560 !important;
}

@media only screen and (max-width: 400px) {
    .mobile_hide {
        display: none !important;
    }

    .main_content {
        overflow: hidden;
    }

    .hero_banner video {
        height: 470px;
        object-fit: cover;
    }

    .characteristics_icon_2 {
        width: max-content;
        margin-right: 20px !important;
    }
}

.characteristics_icon {
    width: 70px;
    margin-right: 10px;
}

.characteristics_icon_1 {
    width: 50px;
    margin-right: 10px;
}

.characteristics_icon_2 {
    width: 45px;
}

.logo_link img {
    max-width: 120px;
    width: 100%;
}

.text-input .input-error {
    border: 2px solid darkred;
}
.phone-input .input-error {
    border: 2px solid darkred!important;
}
.error-message {
    color: darkred;
    font-size: 12px;
}