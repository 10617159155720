.filmar-table {
    overflow: auto;
    table {
      width: 100%;
      border-spacing: 0;
      min-width: max-content;
  
      thead tr th {
        padding: 0 10px 20px 10px;
        border-bottom: 1px solid #d9d9d9;
        text-align: left;
        min-width: max-content;
      }
  
      tbody tr td {
        border-bottom: 1px solid #d9d9d9;
        padding: 10px;
        text-align: left;
        min-width: max-content;
      }
    }
  }
  