.register-form {
    .steps {
      margin-top: 20px;
  
      .progress-step-title {
        color: #666;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        text-align: center;
      }
  
      .progress-bar {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        padding: 0 10%;
      }
  
      .progress-step {
        flex: 1;
        height: 5px;
        background-color: #ddd;
        transition: background-color ease-in-out 250ms;
        border-radius: 4px;
  
        &.active {
          background-color: #333;
        }
      }
    }
  
    form {
      margin-top: 20px;
  
      @media only screen and (min-width: 768px) {
        margin-top: 40px;
      }
  
      .fields {
        display: none;
  
        &.active {
          display: grid;
          gap: 20px;
        }
      }
    }
  
    .actions {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
  
      @media only screen and (min-width: 768px) {
        margin-top: 20px;
      }
  
      .right {
        margin-left: auto;
      }
    }
  
    .back-to-login {
      margin-top: 20px;
      text-align: center;
  
      p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #455560;
      }
  
      a {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        color: #455560;
        text-underline-offset: 4px;
      }
    }
  }