@import '../../../../styles/variables';

.logged-in-as-client-status {
  background: $color-blue-client-status;
  color: $color-white;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 12px 20px;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1024px) {
    margin-left: auto;
    width: 289px;
    position: absolute;
    right: 0;
  }

  &__actions {
    margin-left: auto;
    display: flex;

    &__action {
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;
        margin-left: 10px;
      }
    }
  }
}
