.sub-users-list {
    .header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
  
      @media only screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
  }
  
  /*Utenti*/
  
  .utenti_table_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #455560;
    padding: 0px 0px 20px;
    flex: 1;
    width: 7rem;
  }
  
  .utenti_table_title.icon {
    text-align: center;
  }
  
  .utenti_table_row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    word-wrap: break-word;
  }
  
  .utenti_table_column {
    font-size: 14px;
    line-height: 18px;
    color: #455560;
    padding: 12px 0;
    flex: 1;
    width: 7rem;
  }
  
  .utenti_table_column.icon {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  
  .utenti_table_column.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .utenti_table_column a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #f5f5f5;
    border-radius: 12px;
  }
  
  @media only screen and (max-width: 1024px) {
    .utenti_table_header {
      display: none;
    }
  
    .utenti_table_body {
      border-top: 1px solid #d9d9d9;
    }
  
    .utenti_table_row {
      align-items: center;
      padding: 20px 0;
      gap: 20px;
      position: relative;
    }
  
    .utenti_table_column {
      display: flex;
      flex-direction: column;
      gap: 7px;
      flex: 0 0 calc(50% - 10px);
      padding: 0;
    }
  
    .utenti_table_column:before {
      content: attr(data-label);
      font-weight: 700;
    }
  
    .utenti_table_column.icon {
      flex: 0 0 100%;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 700;
    }
  
    .utenti_table_column.action {
      position: absolute;
      right: 0;
      top: 10px;
      width: auto;
    }
  
    .utenti_table_column.action .action_delete {
      /* display: none; */
    }
  
    .utenti_form_section {
      padding: 50px 20px;
    }
  
    .utenti_form_wrapper {
      padding: 20px;
    }
  
    .utenti_form_title h2 {
      text-align: left;
    }
  
    .utenti_form_title p {
      text-align: left;
      margin-bottom: 26px;
    }
  }
  
  .utenti_table_body {
    margin-top: 10px;
  }
  
  @media only screen and (max-width: 480px) {
    .utenti_form_section .action button {
      width: 100%;
    }
  
    .utenti_form_wrapper .info_block.privileges_info {
      margin-bottom: 20px;
    }
  }
  
  /* Utente Form */
  .main_content.utenti_create,
  .main_content.change_request,
  .main_content.modify_data {
    background: #f5f5f5;
  }
  
  .utenti_form_section,
  .change_request_section,
  .modify_data_section {
    padding: 50px 0 90px;
  }
  
  .utenti_form_wrapper {
    width: 100%;
    max-width: 566px;
    background: #fff;
    border-radius: 12px;
    margin: 0px auto;
    padding: 50px 58px 40px;
    text-align: center;
  }
  
  .utenti_form_title h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #455560;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .utenti_form_title p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #455560;
    margin-top: 0;
    margin-bottom: 40px;
  }
  
  .utenti_form_wrapper .form-input {
    margin-bottom: 20px;
  }
  
  .utenti_form_wrapper .form-input input {
    width: 100%;
    box-sizing: border-box;
    min-height: 50px;
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    padding: 0 15px;
    outline: 0;
    color: #455560;
    font-size: 14px;
    line-height: 18px;
  }
  
  .utenti_form_wrapper .block_title h3 {
    text-align: left;
    margin: 15px 0;
    font-size: 18px;
    line-height: 24px;
  }
  
  .utenti_form_wrapper .info_block {
    margin-bottom: 40px;
  }
  
  .utenti_form_section .action button {
    background: #455560;
    color: #fff;
    border-radius: 12px;
    border: 0;
    padding: 13px 23px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 18px;
  }
  
  .utenti_form_section .create_link p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    color: #455560;
  }
  
  .utenti_form_section .create_link p a {
    font-weight: 700;
    color: #455560;
  }
  