.select-input {
    display: grid;
    gap: 8px;
  
    select {
      width: 100%;
      box-sizing: border-box;
      border-radius: 12px;
      border: 1px solid #d9d9d9;
      padding: 15px;
      //background-color: #F5F5F5;
      text-decoration: none;
      color: #455560;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      cursor: pointer;
      outline: 0;
    }
  }
  