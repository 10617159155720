.drawer-shadow {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;
    overflow: hidden;
    animation: backgroundOpacityTo25 0.3s ease;
  }
  
  @keyframes backgroundOpacityTo25 {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  
  .drawer-shadow .drawer {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: var(--white);
    right: 0;
    overflow: auto;
    animation: slideFromRight 0.25s ease;
  
    @media only screen and (min-width: 768px) {
      padding: 0 40px;
    }
  }
  
  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .drawer-shadow .drawer .header {
    display: flex;
    padding: 40px 0 16px;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    background-color: var(--white);
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .drawer-shadow .drawer .header .title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #455560;
  }
  
  .drawer-shadow .drawer .header .close {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #455560;
    padding: 0;
    border: none;
  }
  
  .drawer-shadow .drawer .content {
    flex: 1;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .drawer-shadow .drawer .footer {
    position: sticky;
    bottom: 0;
    padding: 16px 0 40px;
    border-top: 1px solid #d9d9d9;
    background-color: var(--white);
  }
  
  .drawer-shadow.closing {
    background: rgba(0, 0, 0, 0);
    animation: backgroundOpacityTo0 0.3s ease;
  }
  
  @keyframes backgroundOpacityTo0 {
    from {
      background: rgba(0, 0, 0, 0.5);
    }
    to {
      background: rgba(0, 0, 0, 0);
    }
  }
  
  .drawer-shadow.closing .drawer {
    transform: translateX(100%);
    animation: slideToRight 0.25s ease;
  }
  
  @keyframes slideToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }  