.info-block {
    display: flex;
    flex-direction: column;
    gap: 6px;
  
    &__title {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
  
      &.hide-on-tablet {
        @media only screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  
    &__value {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
  
      &.normal-weight-on-tablet {
        @media only screen and (min-width: 768px) {
          font-weight: 400;
        }
      }
    }
  }  