.auth-form-wrapper {
    background: #fff;
    border-radius: 12px;
    margin: 40px 20px 60px;
    padding: 60px 20px;
    box-sizing: border-box;
  
    @media only screen and (min-width: 768px) {
      max-width: 566px;
      margin: 60px auto 100px;
      padding: 60px;
    }
  }