.p-sample {
    display: grid;
    grid-template-columns: 130px 1fr;
    gap: 10px;
    border-top: 1px solid var(--Light-Grey);
    padding: 20px 0px;

    &.with-border-bottom {
        border-top: none;
        border-bottom: 1px solid var(--Light-Grey);
    }

    img {
        width: 130px;
        height: 150px;
        object-fit: cover;
        border: 1px solid #d9d9d9;
        border-radius: 12px;
    }

    .details {
        display: flex;
        flex-direction: column;

        .title {
            color: var(--Independence);
            font-weight: 700;
        }

        .actions {
            margin-top: auto;
            display: flex;
            justify-content: flex-end;
        }
    }
}