.product-qty-selector {
    display: flex;
    align-items: stretch;
  
    border: 1px solid var(--Light-Grey);
    border-radius: 12px;
    overflow: hidden;
  
    width: 100%;
    max-width: 210px;
  
    color: #455560;
  
    .minus,
    .add {
      cursor: pointer;
      padding: 12px 20px;
      width: min-content;
      color: inherit;
      font-size: 20px;
      transition: background ease-in-out 200ms;
      font-weight: 700;
  
      &:hover {
        background: rgba(153, 153, 153, 0.2);
      }
    }
  
    input {
      border: none;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      color: inherit;
      width: inherit;
  
      &:focus-visible {
        border: none;
      }
    }
  
    &.small {
      max-width: 110px;
  
      .minus,
      .add {
        padding: 8px 10px;
        font-size: 16px;
      }
    }
  }
  