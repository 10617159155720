.f-modal {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);

    .container {
        margin: 30vh auto 0 auto;
        padding: 20px;
        width: 95%;
        max-width: 500px;
        background: white;
        border-radius: 12px;
        display: grid;

        @media only screen and (min-width: 768px) {
            padding: 44px;
        }
    }

    .subscribe-to-news-form {
        display: grid;
        gap: 20px;

        .name-block {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            gap: 20px;
        }
    }
}