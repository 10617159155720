.loading-samples-block {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  
    .loader {
      width: 30px;
      height: 30px;
    }
  }
  
  .samples-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    label {
      cursor: pointer;
    }
  }
  
  .sample {
    display: flex;
    gap: 12px;
    padding: 18px;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    transition: all ease-in 200ms;
    flex-wrap: wrap;
  
    &:has(input:checked) {
      background: #f5f5f5;
      border-color: #f5f5f5;
    }
  
    .sample-details {
      flex: 1;
    }
  
    .title-block {
      display: flex;
      gap: 10px;
  
      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  
    .desc {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
  
      p + p {
        margin-top: 5px;
      }
    }
  
    .sample-subproducts {
      width: 100%;
      max-height: 260px;
      overflow-y: auto;
      display: grid;
  
      .sample-subproduct {
        display: grid;
        grid-template-columns: 20px 150px 1fr 1fr 1fr;
        gap: 5px;
        margin-bottom: 5px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        padding: 5px 10px;
  
        div:has(.checkbox) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        &:has(input:checked) {
          background: #e5e5e5;
          border-color: #e0e0e1;
        }
      }
    }
  
    img {
      width: 56px;
      height: 70px;
      object-fit: contain;
  
      &.has-border {
        border: 1px solid #d9d9d9;
        border-radius: 12px;
        object-fit: cover;
      }
    }
  
    input[type='checkbox'] {
      appearance: none;
      display: none;
    }
  
    input:checked + .checkbox {
      background: var(--Independence);
    }
  
    .checkbox {
      display: block;
      height: 16px;
      border-radius: 5px;
      border: 3px solid var(--Light-Grey);
      width: 16px;
      transition: 100ms ease-in;
      cursor: pointer;
      margin: 0;
    }
  }  