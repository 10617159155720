button.secondary {
    border-radius: 50px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    text-decoration: none;
    font-size: 14px;
    color: #455560;
    padding: 12px 24px;
    text-align: center;
    transition: all ease-in-out 200ms;
  
    &.full-width {
      max-width: 100%;
      width: 100%;
    }
  
    &:hover {
      background-color: #e2e2e2;
      border-color: #e2e2e2;
    }
  
    &:active {
      background-color: #dcdcdc;
      border-color: #dcdcdc;
    }
  
    &:disabled {
      background-color: #e9e9e9;
      border: 1px solid #e9e9e9;
      color: #c8cbcd;
      cursor: default;
      pointer-events: none;
    }
  }
  