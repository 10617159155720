.text-input {
    input {
      width: 100%;
      box-sizing: border-box;
      min-height: 50px;
      border-radius: 12px;
      border: 1px solid #d9d9d9;
      padding: 0 15px;
      outline: 0;
      color: #455560;
      font-size: 14px;
      line-height: 18px;
    }
  }