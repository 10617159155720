.document_table_header .document_table_row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
  }
  
  .documents-filter {
    padding-bottom: 40px;
  }
  
  .documents-filter .left_data {
    display: flex;
    row-gap: 10px;
    column-gap: 15px;
  
    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
      width: 100%;
      .search_field {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
  
  .documents-filter .date {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .documents-filter button:first-child {
    margin-left: auto;
  }
  .documents-filter select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    padding: 12px 18px;
    background-color: #f5f5f5;
    text-decoration: none;
    color: #455560;
    font-size: 14px;
    line-height: 18px;
    border: none; /* Remove default border */
    outline: none; /* Remove default outline */
    cursor: pointer; /* Change cursor on hover */
  
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  
  .document_table_row .document_table_title {
    flex: 1;
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    max-width: 150px;
  
    &.type {
      display: none;
    }
  
    @media only screen and (min-width: 768px) {
      &.type {
        display: block;
        max-width: 200px;
      }
    }
  }
  
  .document_table_body .document_table_row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #d9d9d9;
    font-size: 14px;
    line-height: 18px;
  
    flex-wrap: wrap;
    gap: 10px;
  
    .error {
      max-width: 60ch;
      text-align: center;
      margin: 20px auto;
    }
  
    .document_table_column {
      width: 100%;
    }
  
    @media only screen and (min-width: 768px) {
      .document_table_column {
        width: 150px;
  
        &.type {
          width: 200px;
        }
      }
    }
  }
  