.tabs-component {
    margin-top: 30px;
  
    @media only screen and (min-width: 768px) {
      margin-top: 60px;
    }
  
    &__header {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      border-bottom: 1px solid #d9d9d9;
  
      &__item-name {
        color: #999999;
        cursor: pointer;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        padding: 10px 0;
        border-bottom: 2px solid transparent;
  
        &.active {
          color: #455560;
          border-bottom: 2px solid #1595d3;
        }
      }
    }
  
    &__tabs-content {
      margin-top: 20px;
  
      @media only screen and (min-width: 768px) {
        margin-top: 30px;
      }
  
      &__item {
        &.d-none {
          display: none;
        }
      }
    }
  }  