.auth-form-header {
    .title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #455560;
      margin-top: 0;
      text-align: center;
  
      @media only screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  
    .description {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: #455560;
      margin: 20px auto 0;
      text-align: center;
      max-width: 50ch;
    }
  }
  