.agent-dashboard {
  padding: 40px 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;

  @media only screen and (min-width: 1024px) {
    padding: 60px 0;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.37px;
      text-align: left;

      @media only screen and (min-width: 768px) {
        font-size: 34px;
        font-weight: 700;
        line-height: 44px;
      }
    }
  }
}