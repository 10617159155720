.clients-list {
    .clients-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 1024px) {
            .search-input {
                flex: 1;
            }
        }

        .left {
            display: flex;
            align-items: center;
            column-gap: 20px;

            @media only screen and (max-width: 1024px) {
                flex-wrap: wrap;
                row-gap: 10px;
                column-gap: 15px;
                width: 100%;
            }
        }

        .right {
            .per-page {
                display: flex;
                align-items: center;
                column-gap: 12px;

                select {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 12px;
                    padding: 12px 18px;
                    background-color: #f5f5f5;
                    text-decoration: none;
                    color: #455560;
                    font-size: 14px;
                    line-height: 18px;
                    border: none;
                    /* Remove default border */
                    outline: none;
                    /* Remove default outline */
                    cursor: pointer;
                    /* Change cursor on hover */
                }
            }

            @media only screen and (max-width: 1024px) {
                display: none;
            }
        }
    }

    &__loader-wrapper {
        margin: 10px 0;
    }

    &__list-block {
        margin-top: 20px;
        border-top: 1px solid #d9d9d9;

        @media only screen and (min-width: 768px) {
            border-top: none;
        }

        &__client-item {
            padding: 20px 0;
            border-bottom: 1px solid #d9d9d9;

            &.visible-on-tablet {
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }

            @media only screen and (min-width: 768px) {
                display: grid;
                grid-template-columns: 4fr 2fr;
                gap: 20px;
            }

            &__info {
                display: grid;
                gap: 20px;
                grid-template-columns: 1fr 1fr;

                @media only screen and (min-width: 768px) {
                    grid-template-columns: 1fr 1fr 280px 1fr;
                }

                *:nth-child(1),
                *:nth-child(2) {
                    grid-column: span 2;

                    @media only screen and (min-width: 768px) {
                        grid-column: initial;
                    }
                }

                &__head {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18px;
                }
            }

            &__actions {
                margin-top: 30px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;

                @media only screen and (min-width: 768px) {
                    margin-top: 0;
                }
            }
        }
    }

    &__pagination-block {
        @media only screen and (min-width: 1024px) {
            padding: 0 20px;
        }
    }
}