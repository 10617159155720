.orders-list {
    .orders-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
  
      .left {
        display: flex;
        align-items: center;
        column-gap: 20px;
  
        @media only screen and (max-width: 1024px) {
          flex-wrap: wrap;
          row-gap: 10px;
          column-gap: 15px;
          width: 100%;
  
          .search_field,
          .search_field input {
            width: 100%;
          }
        }
      }
  
      .right {
        .per-page {
          display: flex;
          align-items: center;
          column-gap: 12px;
        }
        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
  
    .order_table_column.sku a {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #455560;
    }
  
    .order_table_column .sub_data > div {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
    }
  
    .order_table_column .sub_data > div a {
      font-size: 12px;
      line-height: 16px;
      color: #1595d3;
    }
  
    .order_table_column .sub_data.date > div {
      min-height: 44px;
    }
  
    .order_table_column .sub_data.date > div:last-child {
      min-height: 34px;
    }
  
    .order_table_column .sub_data > div:last-child {
      padding-bottom: 0;
    }
  
    .order_table_title.totale,
    .order_table_column.totale {
      max-width: 120px;
      text-align: right;
      font-weight: 700;
    }
  
    .order_table_title.actions,
    .order_table_column.actions {
      max-width: 150px;
      padding: 0 20px;
      text-align: right;
    }
  
    .order_table_title.utente,
    .order_table_column.utente {
      max-width: 180px;
    }
  
    .order_table_title.spedizione,
    .order_table_column.spedizione {
      max-width: 200px;
    }
  
    .order_table_title.consegna,
    .order_table_column.consegna {
      max-width: 300px;
    }
  
    .order_table_column.stato {
      font-weight: 700;
      color: #999999;
    }
  
    .order_table_column.stato.delivered {
      color: #35b820;
    }
  
    .order_table_row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d9d9d9;
    }
  
    .order_table_title {
      flex: 1;
      padding-bottom: 20px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      max-width: 120px;
    }
  
    .order_table_row {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #d9d9d9;
      font-size: 14px;
      line-height: 18px;
    }
  
    .order_table_column {
      flex: 1;
      max-width: 120px;
    }
  
    .orders-filter select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      padding: 12px 18px;
      background-color: #f5f5f5;
      text-decoration: none;
      color: #455560;
      font-size: 14px;
      line-height: 18px;
      border: none; /* Remove default border */
      outline: none; /* Remove default outline */
      cursor: pointer; /* Change cursor on hover */
    }
  
    .orders-filter select:focus {
      box-shadow: 0 0 0 2px #8fa3ad;
    }
  
    .orders-filter .date {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  
    .orders-filter button {
      margin-left: auto;
    }
  
    @media only screen and (max-width: 1024px) {
      .order_table_header {
        display: none;
      }
  
      .orders-filter select {
        width: 100%;
      }
  
      .order_table_row {
        flex-wrap: wrap;
        row-gap: 40px;
        col-gap: 20px;
        position: relative;
        padding: 40px 0;
      }
  
      .order_table_column {
        flex: 0 0 50%;
        max-width: unset !important;
        font-weight: 700;
      }
  
      .order_table_column:before {
        content: attr(data-label);
        display: flex;
        font-weight: 400;
        font-size: 14px;
      }
  
      .order_table_column.stato:after {
        content: '';
        width: 14px;
        height: 14px;
        background: #999999;
        display: block;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
      }
  
      .order_table_column.stato.delivered:after {
        background: #35b820;
      }
  
      .order_table_column .sub_data > div {
        padding-bottom: 6px;
      }
  
      .order_table_column.sku {
        order: 1;
      }
  
      .order_table_column.totale {
        text-align: left;
        order: 2;
      }
  
      .order_table_column.data {
        order: 3;
      }
  
      .order_table_column.utente {
        order: 4;
      }
  
      .order_table_column.spedizione {
        order: 5;
      }
  
      .order_table_column.consegna {
        order: 6;
      }
  
      .order_table_column.articoli {
        order: 7;
      }
  
      .order_table_column.quantita {
        order: 8;
      }
  
      .order_table_column.actions {
        order: 9;
        flex: 1;
      }
  
      .order_table_column.stato {
        position: absolute;
        right: 0;
        font-size: 0;
      }
    }
  }  