.personal-data {
    margin-top: 20px;

    @media only screen and (min-width: 768px) {
        margin-top: 60px;
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;

            @media only screen and (min-width: 768px) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        &__link {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #1595d3;

            @media only screen and (min-width: 768px) {
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                color: #455560;
            }
        }
    }

    &__details {
        margin-top: 14px;
        padding: 20px;
        border-radius: 12px;
        background: #f5f5f5;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media only screen and (min-width: 768px) {
            margin-top: 20px;
        }
    }
}