.tickets-list {
    .header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
  
      @media only screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
    .ticket-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
  
      select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px;
        padding: 12px 18px;
        background-color: #f5f5f5;
        text-decoration: none;
        color: #455560;
        font-size: 14px;
        line-height: 18px;
        border: none; /* Remove default border */
        outline: none; /* Remove default outline */
        cursor: pointer; /* Change cursor on hover */
      }
  
      select:focus {
        box-shadow: 0 0 0 2px #8fa3ad;
      }
  
      .date {
        display: flex;
        align-items: center;
        gap: 10px;
      }
  
      button {
        margin-left: auto;
      }
  
      .left {
        display: flex;
        align-items: center;
        column-gap: 20px;
  
        @media only screen and (max-width: 1024px) {
          flex-wrap: wrap;
          row-gap: 10px;
          column-gap: 15px;
          width: 100%;
  
          .search_field,
          .search_field input {
            width: 100%;
          }
        }
      }
      .right {
        .per-page {
          display: flex;
          align-items: center;
          column-gap: 12px;
        }
        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }
      @media only screen and (max-width: 1024px) {
        select {
          width: 100%;
        }
      }
    }
    .content_top {
      margin-bottom: 10px;
    }
    .left {
      display: flex;
      align-items: center;
      column-gap: 20px;
  
      @media only screen and (max-width: 1024px) {
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 15px;
        width: 100%;
  
        .search_field,
        .search_field input {
          width: 100%;
        }
      }
    }
  
    .right {
      .per-page {
        display: flex;
        align-items: center;
        column-gap: 12px;
      }
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .date {
      align-items: center;
      display: flex;
      gap: 10px;
    }
  }  